import { useUserData } from "./useUserData";
import { useEffect, useState } from "react";
import {
  ApproveReportRoles,
  AwarenessReportAdmin,
  CreateReportRoles,
  ShareReportRoles,
  RiskIntelligenceAdmin,
  ShowContactUsRoles,
  ShowEmailRoles,
  BriefsOnlyRoles,
  ItemsHiddenRoles,
} from "../constants/roles";

export const useRoles = () => {
  const { userData: { roles } } = useUserData();
  const [shouldShowContactUs, setShouldShowContactUs] = useState(false);
  const [couldCreateReport, setCouldCreateReport] = useState(null);
  const [couldApproveReport, setCouldApproveReport] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [shouldShowEmails, setShouldShowEmails] = useState(false);
  const [canShareReport, setCanShareReport] = useState(false);
  const [canSeeBriefs, setCanSeeBriefs] = useState(null);
  const [cantSeeItems, setCanSeeItems] = useState(null);

  useEffect(() => {
    if (roles.length > 0) {
      setShouldShowContactUs(roles.some((item) => ShowContactUsRoles.includes(item)));
      setCouldCreateReport(roles.some((item) => CreateReportRoles.includes(item)));
      setCouldApproveReport(roles.some((item) => ApproveReportRoles.includes(item)));
      setCanShareReport(roles.some((item) => ShareReportRoles.includes(item)));
      setIsAdmin(roles.includes(RiskIntelligenceAdmin) || roles.includes(AwarenessReportAdmin));
      setShouldShowEmails(roles.some((item) => ShowEmailRoles.includes(item)));
      setCanSeeBriefs(roles.some((item) => BriefsOnlyRoles.includes(item)));
      setCanSeeItems(!roles.some((item) => ItemsHiddenRoles.includes(item)))
    }
  }, [roles]);

  return {
    shouldShowContactUs,
    couldCreateReport,
    couldApproveReport,
    isAdmin,
    shouldShowEmails,
    canShareReport,
    canSeeBriefs,
    cantSeeItems,
  };
};
